/* eslint-disable vue/valid-template-root */
<template>
  <!-- eslint-disable vue/html-closing-bracket-newline -->
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/html-self-closing -->
  <!-- eslint-disable-next-line vue/valid-template-root -->
  <b-row>
    <!-- <b-col cols="12">
      <Fieldset id="information" :title="$t('Basic Information')">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h2>Third-party shipping</h2>
            <p>Sellect one of kinds of shipping method of Customizing shipping method</p>
          </div>
          <div>
            <b-button v-b-modal.modal-active-thirdparty>
              Enable shipping channels
            </b-button>
          </div>
        </div>
      </Fieldset>
    </b-col> -->
    <b-col cols="12">
      <Fieldset id="options" :title="$t('Options')">
        <div v-for="(item, i) in listThird" :key="i" class="d-flex mt-2 justify-content-between align-items-center">
          <div class="d-flex">
            <img width="100" height="50" src="/images/thirdparty/ghn.png" alt="">
            <div class="ml-4">
              <h3>Kết nối với nhà vận chuyển {{ item.name }}</h3>
              <p>Đơn hàng sẽ tự động đẩy sang hệ thống giao nhận của {{ item.name }} khi bạn xử lý đơn hàng ở iFashion</p>
            </div>
          </div>
          <div>
            <div class="d-flex mb-2">
              <b-form-checkbox
                v-model="model.shipping_mode"
                checked="true"
                class="custom-control-success"
                name="check-button"
                :value="item.mode_value"
                switch
                @change="handleChangeShippingMode"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
              <!-- <span v-if="priceActive">On</span>
              <span v-else>Off</span> -->
            </div>
            <div>
              <b-button
                v-if="!item.status"
                variant="primary"
                type="button"
                @click="openModel(item.model_name, item)"
              >
                Kết nối nhà vận chuyển
              </b-button>
              <b-button
                v-else
                variant=""
                type="button"
                @click="openModel(item.model_name, item)"
              >
                Đã kết nối
              </b-button>
            </div>
          </div>
        </div>
        <!-- <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex">
            <img width="100" height="50" src="/static/images/thirdparty/ghtk.png" alt="">
            <div class="ml-4">
              <h3>Kết nối với nhà vận chuyển Giao Hàng Tiết Kiệm</h3>
              <p>Đơn hàng sẽ tự động đẩy sang hệ thống giao nhận của Giao Hàng Tiết Kiệm khi bạn xử lý đơn hàng ở iFashion</p>
            </div>
          </div>
          <div>
            <div>
              <b-button
                v-b-modal.modal-connect-thirdparty
                variant="primary"
                type="submit"
              >
                Kết nối nhà vận chuyển
              </b-button>
              <b-button
                v-b-modal.modal-connect-thirdparty
                variant="primary"
                type="submit"
              >
                Đã kết nối
              </b-button>
            </div>
          </div>
        </div> -->
      </Fieldset>
    </b-col>
    <!-- Active third party -->
    <b-modal
      id="modal-active-thirdparty"
      ref="active-third-modal-add"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Close"
      centered
      size="lg"
      title="Enable shipping channels"
      @ok="handleActiveThird"
    >
      <div v-for="(item, i) in listThird" :key="i" class="custom-active-third d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <img width="100" height="50" :src="item.img" :alt="item.name">
          <p class="ml-2">
            {{ item.name }}
          </p>
        </div>
        <div>
          <b-form-checkbox
            v-model="item.isActive"
            checked="true"
            class="custom-control-success"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>
      </div>
    </b-modal>
    <!-- Kích hoạt nhà vận chuyển -->
    <b-modal
      id="modal-connect-thirdparty"
      ref="active-third-modal-add"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Close"
      centered
      size="lg"
      title="Connect third-party shipping: Giao Hàng Nhanh"
      @ok="handleActiveThird"
    >
      <div>
        <label>Shipping partner</label>
        <b-form-input
          type="text"
          placeholder="Giao hàng nhanh"
          readonly
        />
      </div>
      <div class="mt-2">
        <label>Client Token</label>
        <div class="d-flex">
          <b-form-input
            v-model="model.token"
            type="text"
            placeholder="Enter the client token"
          />
          <!-- <b-button class="ml-2" @click="handleCheckToken">
            Check
          </b-button> -->
        </div>
        <!-- <label v-if="checkToken">Token information is correct</label>
        <label v-else-if="!checkToken && checkToken != null">It's fail. Token information is incorrect</label> -->
      </div>
      <div class="mt-2">
        <label>Store delivery orders</label>
        <b-form-input
          v-model="model.shop_id"
          type="text"
          placeholder="Enter store delivery orders"
        />
        <!-- <b-form-select
          v-model="model.selected"
          :options="listOption"
        /> -->
      </div>
    </b-modal>

    <b-modal
      id="modal-ghn-edit-token"
      ref="modal-ghn-edit-token"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      title="Connect third-party shipping: Giao Hàng Nhanh"
    >
      <div class="mt-2">
        <label>Client Token</label>
        <div class="d-flex">
          <b-form-input
            v-model="tokenGhn.token"
            type="text"
            placeholder="Enter the client token"
          />
          <!-- <b-button class="ml-2" @click="handleCheckToken">
            Check
          </b-button> -->
        </div>
        <!-- <label v-if="checkToken">Token information is correct</label>
        <label v-else-if="!checkToken && checkToken != null">It's fail. Token information is incorrect</label> -->
      </div>
      <div class="mt-2">
        <label>Store delivery orders</label>
        <b-form-input
          v-model="tokenGhn.shop_id"
          type="text"
          placeholder="Enter store delivery orders"
        />
        <!-- <b-form-select
          v-model="model.selected"
          :options="listOption"
        /> -->
      </div>

      <template #modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button @click="editTokenGhn()">
          Save
        </b-button>
        <b-button variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-ghtk-edit-token"
      ref="modal-ghtk-edit-token"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      title="Connect third-party shipping: Giao Hàng Tiết Kiệm"
    >
      <div class="mt-2">
        <label>Client Token</label>
        <div class="d-flex">
          <b-form-input
            v-model="tokenGhtk.token"
            type="text"
            placeholder="Enter the client token"
          />
          <!-- <b-button class="ml-2" @click="handleCheckToken">
            Check
          </b-button> -->
        </div>
        <!-- <label v-if="checkToken">Token information is correct</label>
        <label v-else-if="!checkToken && checkToken != null">It's fail. Token information is incorrect</label> -->
      </div>
      <template #modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button @click="editTokenGhtk()">
          Save
        </b-button>
        <b-button variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </b-row>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BFormInput,
  // BFormSelect,
} from 'bootstrap-vue'
// import AppTour from '@core/components/app-tour/AppTour.vue'
import general from '@/mixins/index'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BFormRadio,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BFormInput,
    // BFormSelect,
    // AppTour,
  },
  mixins: [general],
  props: {
    data: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      key: 'buso_client_page_config',
      config: [
        {
          key: '',
          value: '',
        },
      ],
      isExistSetting: false,
      // checkToken: null,
      listThird: [
        {
          id: 'ghn',
          name: 'Giao hàng nhanh',
          isActive: true,
          img: '',
          status: 0,
          mode_value: 4,
          model_name: 'modal-ghn-edit-token',
        },
        {
          id: 'ghtk',
          name: 'Giao hàng tiết kiệm',
          isActive: true,
          img: '',
          status: 0,
          mode_value: 5,
          model_name: 'modal-ghtk-edit-token',
        },
      ],
      model: {
        shipping_mode: 0,
        selected: null,
        token: null,
        shop_id: null,
      },
      listOption: [
        {
          value: 1,
          text: '0932357654',
        },
      ],
      tokenGhn: {
        token: '',
        shop_id: '',
      },
      tokenGhtk: {
        token: '',
      },
    }
  },
  mounted() {
    this.loadSiteInfo()
    this.loadConfig()
  },
  methods: {
    openModel(name) {
      this.$refs[name].show()
    },
    async checkTokenGhn() {
      const res = await Request.get(
        this.$http,
        `${process.env.VUE_APP_API_URL}/ghn/check_token?token=${this.tokenGhn.token}`,
      )
      return res.data.status
    },
    async editTokenGhn() {
      const isStatus = await this.checkTokenGhn()
      if (isStatus === false) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String('Token not valid'),
          },
        })
      } else {
        try {
          const params = {
            token_ghn: JSON.stringify(this.tokenGhn),
          }
          const res = await Request.put(
            this.$http,
            `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}`,
            params,
          )
          if (res.status === 200) {
            if (res.data.status) {
              this.listThird.find(x => x.id === 'ghn').status = 1
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Congratulation !',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'Update token success',
                },
              })
              this.$refs['modal-ghn-edit-token'].hide()
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Opps! Something wrong',
                  icon: 'AlertOctagonIcon',
                  variant: 'danger',
                  text: String(this.showError(res.data.error, ',')),
                },
              })
            }
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(error),
            },
          })
        }
      }
    },

    async editTokenGhtk() {
      try {
        const params = {
          token_ghtk: JSON.stringify(this.tokenGhtk),
        }
        const res = await Request.put(
          this.$http,
          `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.listThird.find(x => x.id === 'ghtk').status = 1
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Congratulation !',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Update token success',
              },
            })
            this.$refs['modal-ghtk-edit-token'].hide()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadConfig() {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/setting/${this.key}?site_id=${
            JSON.parse(localStorage.getItem('siteID')).id
          }`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.isExistSetting = true
            this.config = JSON.parse(res.data.data.value)
            if (this.config.find(x => x.key === 'shipping_mode') !== undefined) {
              this.model.shipping_mode = this.config.find(x => x.key === 'shipping_mode').value
            }
          } else {
            this.isExistSetting = false
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handleChangeShippingMode() {
      try {
        if (this.config.find(val => val.key === 'shipping_mode') === undefined) {
          this.config.push({
            key: 'shipping_mode',
            value: this.model.shipping_mode,
          })
        } else {
          this.config.find(val => val.key === 'shipping_mode').value = this.model.shipping_mode
        }
        const params = {
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          key: this.key,
          value: JSON.stringify(this.config),
        }
        let res = null
        if (!this.isExistSetting) {
          res = await Request.post(
            this.$http,
            `${process.env.VUE_APP_API_URL}/settings`,
            params,
          )
        } else {
          res = await Request.put(
            this.$http,
            `${process.env.VUE_APP_API_URL}/setting/${this.key}`,
            params,
          )
        }
        if (res.status === 200) {
          if (res.data.status) {
            // this.loadConfig()
            this.isExistSetting = true
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadSiteInfo() {
      try {
        const res = await Request.get(this.$http, `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}?user_id=${JSON.parse(localStorage.getItem('token')).id}`)
        if (res) {
          if (res.data.status) {
            this.tokenGhn = JSON.parse(res.data.data.token_ghn || '{}')
            this.tokenGhtk = JSON.parse(res.data.data.token_ghtk || '{}')
            if (res.data.data.token_ghn !== undefined && res.data.data.token_ghn !== null) {
              this.listThird.find(x => x.id === 'ghn').status = 1
            }
            if (res.data.data.token_ghtk !== undefined && res.data.data.token_ghtk !== null) {
              this.listThird.find(x => x.id === 'ghtk').status = 1
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handleActiveThird() {
      try {
        const params = {
          token_ghn: JSON.stringify({
            token: this.model.token,
            shop_id: this.model.shop_id,
            // token: '4cec2543-c1dd-11eb-8546-ca480ac3485e',
            // shop_id: '80192',
          }),
        }
        const res = await Request.put(
          this.$http,
          `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.listThird.find(x => x.name === 'Giao hàng nhanh').status = 1
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Congratulation !',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Update Payment Account success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    // handleCheckToken() {
    //   this.checkToken = true
    // },
  },
}
</script>
<style lang="sass" scoped>
.custom-active-third
  border: 1px solid #999
  border-radius: 8px
  margin-bottom: 16px
  padding: 16px
.container-ctrl
  padding-left: 1rem
  padding-right: 1rem

  .box-cate
    background-color: #F7F7F7
    padding: 1rem

    .cate-list
      background-color: #fff
      margin-top: 1rem
      padding: 1rem
      min-height: 25rem

      .list-item
        padding-left: 0
        overflow-y: scroll
        height: 25rem

        .active
          font-weight: 500
          background-color: #f5f5f5
        .item
          display: flex
          justify-content: space-between
          list-style: none
          padding: 10px 15px
          // text-decoration: none
          color: #000
          cursor: pointer
          &.d-none-after
            &::after
              display: none
              opacity: 0

          & > span
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis

          &:hover
            font-weight: 500
            background-color: #f5f5f5

          &::after

            content: ""
            display: inline-block
            vertical-align: middle
            width: 6px
            height: 13px
            margin-left: 1rem
            transition: transform 350ms
            transform: rotate(0)
            border-width: .5rem 0 .5rem .5rem
            border-color: transparent transparent transparent #ccc
            border-style: solid
  .current
    margin-top: 1rem
</style>
